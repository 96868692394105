@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .nav-link {
    @apply relative font-semibold text-white cursor-pointer text-[17px] w-fit 
      md:block after:block after:content-[''] after:absolute after:h-[3px]
      after:w-full after:scale-x-0 after:hover:scale-x-100
      after:transition after:duration-300 after:origin-center hidden;
  }

  .nav-link:hover {
    background: linear-gradient(to top, #d32f2f, #fbc02d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .nav-link-mobile {
    @apply relative mb-[2rem] font-semibold text-white cursor-pointer text-[27px] w-fit 
      md:block after:block after:content-[''] after:absolute after:h-[3px]
      after:bg-orange-400 after:w-full after:scale-x-0 after:hover:scale-x-100
      after:transition after:duration-300 after:origin-center;
  }
  .nav-link-mobile:hover {
    background: linear-gradient(to top, #d32f2f, #fbc02d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .heading {
    @apply text-center text-white text-[24px] sm:text-[29px] md:text-[43px] font-bold uppercase;
  }

  .project-details {
    position: relative;
    background-color: #121212; /* Primary background color */
    border: solid 1px #55e6a5; /* Border around the element */
    border-radius: 8px; /* Apply a border radius to the container */
    overflow: visible; /* Change to visible to allow content outside the border */
  }

  .project-details::after {
    content: "";
    position: absolute;
    top: 0;
    right: -20px;
    bottom: 0;
    width: 20px;
    background-image: repeating-linear-gradient(
      45deg,
      #55e6a5 0,
      #55e6a5 2px,
      transparent 2px,
      transparent 10px
    );
    border-radius: 0 8px 8px 0;
  }

  .fade-right-initial {
    opacity: 0;
    transform: translateX(20px);
  }

  .fade-right-enter {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms, transform 500ms;
  }
}
