.text-sphere {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tagcloud {
  display: inline-block;
  top: 0;
  left: 0;
  font-weight: 600;
  letter-spacing: 0.0625rem;
  font-size: 1.3rem;
}

.tagcloud--item {
  color: #55e6a5;
}

.tagcloud--item:hover {
  color: #fb923c;
}
